




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { itemHoverConfig } from '@/services/Factories/Item/ItemFactory'

@Component
export default class ItemCard extends Vue {
  @Prop() item

  itemHoverConfig = itemHoverConfig

  displayItemVNum() {
    return this.$route.path.includes('view') ? ` (${this.item.vnum})` : ''
  }

  get isGMRoute() {
    return this.$route.fullPath.includes('gm') || this.$route.name === 'admin-character-id'
  }
}
